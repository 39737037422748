import constants from 'appConstants';
import { groupBy, isNumber } from 'lodash-es';
import { getHiveIndexForFrame } from '../../../../utils';

const setEndOfHive = ({ frame, hive, frameWidth = 0 }) => {
    const lastFrameX = Math.ceil(frame.place.position.x + frameWidth / 2);
    hive.to = lastFrameX;
};

export const groupFramesByHives = ({ station, groupedFramesByStation, stations }) => {
    if (!stations) {
        return [];
    }
    const { start } = stations[station] || {};
    const frames = groupedFramesByStation?.[station]?.sort(
        (frameA, frameB) => frameA.place.position.x - frameB.place.position.x
    );
    return (
        frames
            ?.reduce((acc, frame, index, fullArr) => {
                const prevHive = acc[acc.length - 1];
                const { type } = frame;
                const isLastFrameInStation = index === fullArr.length - 1;
                const frameWidth = constants.DEFAULT_FRAME_WIDTH[type.toUpperCase()];
                const isIllegalHive =
                    acc.length &&
                    !prevHive?.to &&
                    prevHive.frames.some(frame => constants.FRAME_TYPES.COMB_FRAME === frame.type) &&
                    prevHive.frames.some(frame => constants.FRAME_TYPES.FEEDER === frame.type) &&
                    prevHive?.frames.some(frame => constants.FRAME_TYPES.PARTITION === frame.type) &&
                    type === constants.FRAME_TYPES.PARTITION;

                if (isIllegalHive) {
                    const prevFrame = fullArr[index - 1];
                    setEndOfHive({ hive: prevHive, frame: prevFrame, frameWidth });
                }
                const prevHiveEndX = prevHive?.to;
                const isPrevHiveFull = !!prevHiveEndX;

                const isNewHive = !acc.length || isPrevHiveFull;
                if (isNewHive) {
                    // handle cases if station end and start are not defined
                    const stationStart = start?.x ?? frame.place.position.x - frameWidth / 2;
                    acc.push({
                        from: !prevHive ? stationStart : prevHiveEndX,
                        to: null,
                        frames: [frame],
                        station,
                        index: acc.length,
                        id: `${station}-${frame.place.position.x}`,
                        name: `${station.toUpperCase()}${acc.length + 1}`,
                    });
                } else {
                    // add frames to prev hive
                    prevHive.frames.push(frame);
                    // add end of prev hive if it's last frame in station or frame is static partition
                    if (constants.FRAME_TYPES.STATIC_PARTITION === type || isLastFrameInStation) {
                        setEndOfHive({ frame, hive: prevHive, frameWidth });
                    }
                }
                return acc;
            }, [])
            // add filter to remove cases of duplicated static partitions in the end of layout
            .filter(hive => isNumber(hive.from) && isNumber(hive.to)) ?? []
    );
};

export const groupFramesByStationAndHives = (frames, stations) => {
    const groupedFramesByStation = groupBy(frames, 'place.station');
    const stationAHives = groupFramesByHives({ station: constants.STATIONS.A, groupedFramesByStation, stations });
    const stationBHives = groupFramesByHives({ station: constants.STATIONS.B, groupedFramesByStation, stations });

    return {
        [constants.STATIONS.A]: stationAHives,
        [constants.STATIONS.B]: stationBHives,
    };
};

const FRAME_COMMANDS = [constants.COMMANDS.SCAN, constants.COMMANDS.COUNT_BEES, constants.COMMANDS.FILL_FEEDER];

export const getMostFrequentMatch = arr => {
    const frequencyMap = new Map();
    arr.forEach(num => frequencyMap.set(num, (frequencyMap.get(num) || 0) + 1));
    return [...frequencyMap.entries()].reduce((a, b) => (b[1] > a[1] ? b : a))[0];
};

const getPayloadForCommand = ({ command, payload, hives }) => {
    const isFrameCommand = FRAME_COMMANDS.includes(command);
    if (isFrameCommand) {
        const hiveIndexes = payload.frames?.map(frame => getHiveIndexForFrame(hives, frame));
        const hiveIndex = hiveIndexes && getMostFrequentMatch(hiveIndexes);

        return { hive: hiveIndex !== -1 && hives[hiveIndex]?.name };
    }
    return payload;
};

const getPayloadForGroupedMessages = ({ messages, hives }) => {
    const [firstMessage] = messages;
    const { command } = firstMessage;

    if (FRAME_COMMANDS.includes(command)) {
        const messageHives = messages
            .map(({ data: { hive } }, index) => ({
                hive,
                id: messages[index].id,
            }))
            .filter(({ hive }) => Boolean(hive))
            .sort((a, b) => a.hive.localeCompare(b.hive));

        const uniqueHives = [...new Set(messageHives.map(({ hive }) => hive))];

        return {
            hives: uniqueHives,
            messages: messageHives.filter(({ hive }) => uniqueHives.includes(hive)).map(({ id }) => id),
            isAllHives: uniqueHives.length === hives.length,
        };
    }

    return firstMessage.data;
};

export const mapHivesToMessages = (filteredMessages, hivesByStation) => {
    const hives = [...hivesByStation[constants.STATIONS.A], ...hivesByStation[constants.STATIONS.B]];
    const messagesWithHives = filteredMessages.map(({ payload, id, command, sentAt, user, status }) => ({
        id,
        command,
        sentAt: new Date(sentAt),
        user,
        data: getPayloadForCommand({ command, payload, hives }),
        status,
    }));
    const groupedMessages = groupBy(
        messagesWithHives,
        ({ user, command, sentAt }) => `${user}-${command}-${sentAt.toLocaleDateString()}`
    );
    const groupedArray = Object.values(groupedMessages).map(messages => ({
        command: messages[0].command,
        sentAt: messages[0].sentAt,
        user: messages[0].user,
        data: getPayloadForGroupedMessages({ messages, hives }),
        status: messages[0].status,
    }));

    return groupedArray.sort((a, b) => b.sentAt - a.sentAt);
};
