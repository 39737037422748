import React from 'react';
import dayjs from 'dayjs';
import { keyBy } from 'lodash-es';

export const ZOOM_LEVELS = {
    LOCATION_MIN: 11,
    SPLIT_RANCHES: 12,
    BHOME_MIN: 15,
};

const BASE_OFFSET = 0.0005;

export const getFixedOffset = index => BASE_OFFSET * index;

export const getBhomeLabel = bhome => bhome.id && `#${bhome.id}`;
const getBhomeWithStatus = ({ bhome, online }) => ({
    bhome_ids: [bhome.id],
    lat: bhome?.gps?.latitude && Number(bhome?.gps?.latitude),
    lng: bhome?.gps?.latitude && Number(bhome?.gps?.longitude),
    gpsUpdateDate:
        bhome.gps?.timestamp &&
        typeof bhome.gps?.timestamp === 'number' &&
        dayjs.tz(bhome.gps.timestamp, bhome.gps.timezone).utc(),
    name: `Bhome ${bhome.id} GPS`,
    locationId: bhome.location_id,
    online,
});

const getCluster = markers =>
    markers.map(marker => ({
        type: 'Feature',
        properties: { cluster: false, crimeId: marker.id },
        marker,
        geometry: {
            type: 'Point',
            coordinates: [parseFloat(marker.lng), parseFloat(marker.lat)],
        },
    }));

export const getMarkersWithClusters = ({ bhomes, bhomesOnline, locations }) => {
    const bhomeByKeys = keyBy(bhomes, 'id');
    const locationsByKeys = keyBy(locations, 'id');

    const { emptyLocations, notEmptyLocations } = locations.reduce(
        (acc, location) => {
            const ids = location.bhome_ids?.filter(bhomeId => bhomeByKeys[bhomeId]);
            const destination = ids.length ? acc.notEmptyLocations : acc.emptyLocations;
            destination.push({
                ...location,
                bhome_ids: ids,
            });
            return acc;
        },
        { emptyLocations: [], notEmptyLocations: [] }
    );

    const unassignedBhomes = bhomes
        .map(bhome => getBhomeWithStatus({ bhome, online: bhomesOnline.has(bhome.id) }))
        .filter(bhome => !locationsByKeys[bhome.locationId] && bhome.lat && bhome.lng);

    const bhomeMarkers = [...unassignedBhomes, ...notEmptyLocations];

    return {
        bhomeMarkers,
        locationMarkers: emptyLocations,
        bhomeMarkersForClusters: getCluster(bhomeMarkers),
        emptyMarkersForClusters: getCluster(emptyLocations),
    };
};

export const updateMapBounds = (map, points, zoomLevel) => {
    if (!points.length || !map || !window.google?.maps) {
        return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    points.forEach(
        point => point.lat && point.lng && bounds.extend(new window.google.maps.LatLng(point.lat, point.lng))
    );

    map.fitBounds(bounds);
    if (zoomLevel) {
        map.setZoom(zoomLevel);
    } else {
        const currentZoom = map.getZoom();
        if (currentZoom > 15) {
            map.setZoom(15);
        }
    }
};

export const handleSmoothCentering = (entity, map) => map.panTo({ lat: +entity.lat, lng: +entity.lng });

export const handleSmoothZoom = (entity, map) => {
    handleSmoothCentering(entity, map);
    entity.isRanch ? map.setZoom(ZOOM_LEVELS.SPLIT_RANCHES) : map.setZoom(ZOOM_LEVELS.BHOME_MIN);
};

export const handleSmoothZoomOnListClick = (entity, map) => {
    handleSmoothCentering(entity, map);
    entity.isRanch ? map.setZoom(ZOOM_LEVELS.LOCATION_MIN) : map.setZoom(ZOOM_LEVELS.BHOME_MIN);
};

export const formatEntityNameToMultiLine = entityName =>
    entityName.split(/\s+/).reduce((acc, word, index) => {
        if (index % 4 === 0) {
            acc.push(<br key={`${word}-${index}`} />);
        }
        acc.push(`${word} `);
        return acc;
    }, []);
